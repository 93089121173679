const educationDataDetails = [
  {
    level: "Уровень 1",
    title:
      "Data Science. Уровень 1: Основные технологии. Python для анализа данных",
    text:
      "Data Science сегодня — это одно из самых востребованных направлений подготовки в бизнес-аналитике. В основе data science лежит работа с большими данными (Big Data). Большие данные — это действительно огромные массивы неструктурированной информации, для работы с которыми используются методы машинного обучения и математическая статистика.Основное отличие data science от классических методов бизнес-аналитики — это поиск связей и закономерностей в массивах информации, для разработки моделей, предсказывающей результат.",
  },
  {
    level: "Уровень 2",
    title: "Data Science. Уровень 2: Машинное обучение",
    text:
      "Машинное обучение (machine learning) — один из основным методов для дата-сайентиста, специалиста по работе с большими данными. Основная идея машинного обучения — на основе методов математической статистики и оптимизации создать самообучающийся алгоритм, который будет находить связи и закономерности в больших массивах неструктурированных данных.",
  },
  {
    level: "Уровень 3",

    title: "Data Science. Уровень 3: Нейронные сети",
    text:
      "Нейронные сети – это математические модели и их программное воплощение. Нейронная сеть имитирует структуру и свойства организации нервной системы живых организмов и позволяет решать задачи с заданным алгоритмом и формулами, учитывая прошлый опыт.Это обучаемая система. Основная идея использования нейросетей – решение сложных задач, для которых стоит лишь задать некоторый критерий качества, который будет минимизирован или оптимизирован.Нейронная сеть способна обучаться решению задач, для которых у человека не существует формализованных, быстрых или работающих с приемлемой точностью теоретических или эмпирических алгоритмов.",
  },
  {
    level: "Уровень 4",

    title: "Data Science. Уровень 4: Нейронные сети. Продвинутый уровень",
    text:
      "Нейронные сети – это математические модели и их программное воплощение. Нейронная сеть способна обучаться решению задач, для которых у человека не существует формализованных, быстрых или работающих с приемлемой точностью теоретических или эмпирических алгоритмов. Продвинутый уровень работы с нейросетями позволит дата саентисту решать более сложные, глубокие задачи, освоить проблему переобучения созданной модели, работу с чат-ботами, распознаванием объектов и другие.",
  },
  {
    level: "Машинное и глубокое обучение",

    title:
      "Машинное и глубокое обучение, прикладной искусственный интеллект и сильный искусственный интеллект",
    text:
      "Машинное и глубокое обучение (Machine and deep learning) — технологии искусственного интеллекта, в основе которых лежит самообучение созданных алгоритмов. Машинное обучение сегодня используется в автомобилестроении, поиске информации в интернете, при решении логистических задач, при алгоритмах выдачи рекламы конкретному пользователю и многих других сферах. Но самое важное, что именно искусственный интеллект и машинное обучение позволяют компаниям получать стратегические конкурентные преимущества, которые сложно скопировать другим. Например, система самопилотируемых автомобилей Tesla сегодня не имеет аналогов даже среди крупнейших автопроизводителей, что делает эту компанию самой дорогой в этой сфере при относительно невысоких объемах производства.",
  },
];

export default educationDataDetails;
