const posts = [
  { id: "1", name: "Главная", link: "/" },
  { id: "2", name: "О нас", link: "/about-us" },
  { id: "3", name: "Наша команда", link: "/our-team" },
  { id: "4", name: "Новости", link: "/news" },
  { id: "5", name: "Контакты", link: "/contact-us" },
  { id: "6", name: "Community", link: "/community" },
  { id: "7", name: "Сообщество", link: "/community" },
  { id: "8", name: "Обучение", link: "/education" },
  { id: "9", name: "Вакансии", link: "/vacancies" },
  { id: "10", name: "Baum UDS", link: "/baum-uds" },
  { id: "11", name: "Функционал BAUM UDS", link: "/baum-uds/functional" },
  { id: "12", name: "Модель BAUM UDS", link: "/baum-uds/model" },
  { id: "13", name: "Документация BAUM UDS", link: "/baum-uds/documentation" },
  { id: "14", name: "Поддержка BAUM UDS", link: "/baum-uds/support" },
  { id: "15", name: "BAUM SWARM", link: "/baum-swarm" },
  { id: "16", name: "Функционал BAUM SWARM", link: "/baum-swarm/functional" },
  { id: "17", name: "Модель BAUM SWARM", link: "/baum-swarm/model" },
  {
    id: "18",
    name: "Документация BAUM SWARM",
    link: "/baum-swarm/documentation",
  },
  { id: "19", name: "Поддержка BAUM SWARM", link: "/baum-swarm/support" },
  { id: "20", name: "Платформа AI", link: "/ai/platform" },
  { id: "21", name: "Функционал AI", link: "/ai/platform/functional" },
  { id: "22", name: "Модель AI", link: "/ai/platform/model" },
  { id: "23", name: "Документация AI", link: "/ai/platform/documentation" },
  { id: "24", name: "Поддержка AI", link: "/ai/platform/support" },
  { id: "25", name: "Программно-технические комплексы", link: "/hardware" },
  { id: "26", name: "Специальное программное обеспечение", link: "/software" },
];

export default posts;
