const vacantionsData = [
  {
    vacancy: "AI Software Developer",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Разбираться в разработке ПО, с применением микросервисов;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Писать микросервисы и настраивать взаимодействие между ними;",
          },
          {
            name: "list",
            type: "li",
            text: "Уметь разбираться в бизнес-требованиях заказчика;",
          },
          {
            name: "list",
            type: "li",
            text: "Работать с git.",
          },
        ],
      },
    ],
    taskTypes: [
      {
        title: "Типы задач",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Обеспечивать взаимодействия DS кода с платформой;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Разрабатывать новый функционал, с применением микросервисов;",
          },
          {
            name: "list",
            type: "li",
            text: "Разрабатывать новый drag&drop функционал конструктора ИИ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Обеспечивать взаимодействие компонентов платформы с БД (SQL & NoSQL);",
          },
        ],
      },
    ],
    stack: {
      title: "Наш основной стек",
      name: "title",
      type: "h4",
      text: {
        name: "text",
        type: "p",
        text: "Python, kafka, MongoDB, REST, nginx reach - gui.",
      },
    },
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе/удаленно);",
          },
          {
            name: "list",
            type: "li",
            text: "Комфортабельный офис рядом с метро Бауманская.",
          },
        ],
      },
    ],
  },

  {
    vacancy: "IT Project Manager",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Высшее образование;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Опыт работы в области IT (в роли менеджера проекта, pre-sale);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с системами управления проектов (Jira, RedMine)",
          },
          {
            name: "list",
            type: "li",
            text: "Английский язык (на уровне чтения профильной документации);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Аналитические способности, самостоятельность и ответственность;",
          },
          {
            name: "list",
            type: "li",
            text: "Способность обучаться и осваивать новые технологии;",
          },
          {
            name: "list",
            type: "li",
            text: "Готовность брать на себя ответственность за результат;",
          },
          {
            name: "list",
            type: "li",
            text: "Проактивность.",
          },
        ],
      },
    ],
    welcomed: [
      {
        title: "Приветствуются",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стремление расширять свой кругозор и повышать профессиональный уровень;",
          },
          {
            name: "list",
            type: "li",
            text: "Любой опыт разработки ПО;",
          },
          {
            name: "list",
            type: "li",
            text: "Умение работать в режиме многозадачности",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Разработка, согласование и утверждение плана проекта, в том числе:",
            list: {
              name: "list",
              type: "ul",
              inside_blocks: [
                {
                  name: "list",
                  type: "li",
                  text:
                    "определение краткосрочных, среднесрочных и долгосрочных целей и задач проекта в рамках общего срока проекта (определение потребности заказчика в IT оборудовании и услугах);",
                },
                {
                  name: "list",
                  type: "li",
                  text: "определение состава проектной группы;",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "распределение ролей и обязанностей между членами проектной группы;",
                },
                {
                  name: "list",
                  type: "li",
                  text: "утверждение проекта у генерального директора.",
                },
              ],
            },
          },

          {
            name: "list",
            type: "li",
            text: "Управление проектом, в том числе:",
            list: {
              name: "list-ul",
              type: "ul",
              inside_blocks: [
                {
                  name: "list",
                  type: "li",
                  text: "управление членами проектной группы;",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "контроль исполнения задач и достижения целей, установленных планом проекта (ведение базы данных по существующим и перспективным клиентам);",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "обеспечение коммуникации между проектной группой и заказчиком проекта (взаимодействие с заказчиками в рамках планирования, подготовки и реализации IT-проектов в качестве менеджера проектов, поддержка и развитие деловых связей с существующими клиентами, осуществление pre-sale консультаций/переговоров по потребности заказчика, расчет, подготовка коммерческих и тендерных предложений);",
                },
                {
                  name: "list",
                  type: "li",
                  text: "проведение плановых и внеплановых планерок;",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "анализ вопросов, возникающих в ходе проекта, выявление причин возникновения  и разработка решений по их устранению;",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "разработка необходимых изменений для внесения в план проекта, а также их согласование с заказчиком;",
                },
                {
                  name: "list",
                  type: "li",
                  text:
                    "подготовка финальной презентации продукта для сдачи заказчику.",
                },
              ],
            },
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в организации и проведении маркетинговых мероприятий.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (офис Москва, метро Бауманская);",
          },
        ],
      },
    ],
  },

  {
    vacancy: "Data Scientist",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Разбираться в типах данных;",
          },
          {
            name: "list",
            type: "li",
            text: "Разбираться в алгоритмах ML и AI;",
          },
          {
            name: "list",
            type: "li",
            text: "Уметь быстро писать прототипы;",
          },
          {
            name: "list",
            type: "li",
            text: "Составлять датасеты, склеивать и очищать их;",
          },
          {
            name: "list",
            type: "li",
            text: "Уметь разбираться в бизнес-требованиях заказчика;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Уметь разрабатывать модели ИИ, сохранять их и внедрять в production (flask, docker);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Уметь описать (и защитить) процесс обучения и использования модели;",
          },
          {
            name: "list",
            type: "li",
            text: "Уметь работать с git.",
          },
          {
            name: "list",
            type: "li",
            text:
              "Также будет плюсом: знание MLOps, опыт работы с временными рядами и NLP задачами, опыт",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Работать в команде;",
          },
          {
            name: "list",
            type: "li",
            text: "Вести текущую ML разработку;",
          },
          {
            name: "list",
            type: "li",
            text: "Делать прототипы;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Решать нетривиальные задачки по запросу заказчиков (а их хватает);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Объяснять свои решения для разработчиков, и внедрять модели в платформу.",
          },
        ],
      },
    ],
    stack: {
      title: "Наш основной стек",
      name: "title",
      type: "h4",
      text: {
        name: "text",
        type: "p",
        text: "Ubuntu, Airflow, kafka, hadoop, spark, Postgresql, Hbase, Pandas, numpy, pyspark, Sklearn, tensorflow, sparkML, Flask, Docker",
      },
    },
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (офис Москва, метро Бауманская);",
          },
        ],
      },
    ],
  },

  {
    vacancy: "System Engineer",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Опыт работы с ОС Windows, ОС Linux, гипервизорами VMware, KVM (экспертная настройка, поддержка, поиск и устранение неисправностей, мониторинг);",
          },
          {
            name: "list",
            type: "li",
            text: "Глубокое понимание принципов TCP/IP;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с СХД;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с серверным оборудованием;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание принципов построения LAN сетей, основные службы и протоколы (DHCP, DNS, SNMP, ICMP, TCP, UDP, HTTP(S), FTP, SMTP);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Английский язык (на уровне чтения профильной документации);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Общие знания, необходимые для работы в консоли Unix/Linux (bash,awk);",
          },
          {
            name: "list",
            type: "li",
            text: "Аналитические способности, самостоятельность и ответственность;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Способность обучаться и осваивать новые технологии;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Обязательный опыт работы в данной сфере.",
          },
        ],
      },
    ],
    welcomed: [
      {
        title: "Приветствуeтся",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Любые знания по SAN: основные принципы, конфигурирование оборудования;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт написания технической документации;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание архитектуры и принципов работы файловых систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Стремление расширять свой кругозор и повышать профессиональный уровень;",
          },
          {
            name: "list",
            type: "li",
            text: "Любой опыт разработки ПО.",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Проведение работ по анализу проблем, поиску неисправностей, настройке (в том числе удаленной) эксплуатируемых программно-технических комплексов;",
          },
          {
            name: "list",
            type: "li",
            text: "Проведение работ по тестированию разрабатываемых систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Обеспечение работоспособности лабораторного стенда;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Выработка рекомендаций по улучшению продукции;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Проведение пусконаладочных работ на территории заказчиков;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Выезд к заказчику для решения сложных технических проблем, а также для гарантийного и постгарантийного обслуживания;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в организации и проведении демонстраций продуктов.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (офис Москва, метро Бауманская);",
          },
        ],
      },
    ],
  },

  {
    vacancy: "Web Developer",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Знание архитектуры React (Понимание на что нужно обращать внимание);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с Gulp/Webpack;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт кроссплатформенной верстки и верстки под мобильные приложения;",
          },
          {
            name: "list",
            type: "li",
            text: "Отличные знания современных JavaScript/Typescript, HTML и CSS;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с Redux и библиотеками UI (Material UI/Ant Design);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Умение работать с git;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Опыт работы с препроцессорами (Less, Sass);",
          },
          {
            name: "list",
            type: "li",
            text: "Инструменты сборки и таск-раннеры (Webpack/gulp);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Опыт работы с Websockets;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Большой опыт работы с *nix системами;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Аналитические способности, самостоятельность и ответственность;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Способность обучаться и осваивать новые технологии;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Способность ориентироваться в чужом коде и работать в команде.",
          },
        ],
      },
    ],
    welcomed: [
      {
        title: "Приветствуeтся",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Опыт работы с MongoDB;",
          },
          {
            name: "list",
            type: "li",
            text: "Понимание принципов SPA и изоморфных web-приложений;",
          },
          {
            name: "list",
            type: "li",
            text: "Знаком с Node.js;",
          },
          {
            name: "list",
            type: "li",
            text: "Большим плюсом будет являться знание python/jango.",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Проектирование и разработка web-приложения;",
          },
          {
            name: "list",
            type: "li",
            text: "Написание программного кода на JS/Typescript, SCSS, HTML5, ReactJS/Redux;",
          },
          {
            name: "list",
            type: "li",
            text: "Адаптировать контент для отображения на мобильных устройствах;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Взаимодействие со смежными подразделениями;",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе Москва, либо Воронеж/удаленно).",
          },
        ],
      },
    ],
  },

  {
    vacancy: "Developer С++",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Отличное знание языка С++ и наличие опыта программирования с его использованием;",
          },
          {
            name: "list",
            type: "li",
            text: "Английский язык (на уровне чтения профильной документации);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт отладки, профилировки и оптимизации кода под Unix/Linux;",
          },
          {
            name: "list",
            type: "li",
            text: "Общие знания, необходимые для работы в консоли Unix/Linux (bash,awk);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с системами контроля версий (git);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Аналитические способности, самостоятельность и ответственность;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Способность обучаться и осваивать новые технологии;",
          },
          {
            name: "list",
            type: "li",
            text: "Способность ориентироваться в чужом коде и работать в команде.",
          },
        ],
      },
    ],
    welcomed: [
      {
        title: "Приветствуeтся",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Опыт разработки кластерных систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Понимание архитектуры и принципов построения ОС;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание архитектуры и принципов работы файловых систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в проектах на уровне ядра ОС;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание языка Python и опыта программирования с его использованием;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт разработки драйверов для SCSI и сетевых устройств;",
          },
          {
            name: "list",
            type: "li",
            text: "Осознание специфики низкоуровневой разработки;",
          },
          {
            name: "list",
            type: "li",
            text: "Стремление расширять свой кругозор и повышать профессиональный уровень;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в opensource проектах.",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Разработка модулей программного обеспечения на языке C++ для Unix-подобных систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в проектировании архитектуры ПО;",
          },
          {
            name: "list",
            type: "li",
            text: "Интеграция разработанных модулей в конечный продукт;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Поиск и исправление найденных ошибок, поддержка существующего кода;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в организации и проведении демонстраций продуктов.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе Москва/Воронеж).",
          },
        ],
      },
    ],
  },

  {
    vacancy: "Developer С",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Отличное знание языка С и наличие опыта программирования с его использованием;",
          },
          {
            name: "list",
            type: "li",
            text: "Английский язык (на уровне чтения профильной документации);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт отладки, профилировки и оптимизации кода под Unix/Linux;",
          },
          {
            name: "list",
            type: "li",
            text: "Общие знания, необходимые для работы в консоли Unix/Linux (bash,awk);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с системами контроля версий (git);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Аналитические способности, самостоятельность и ответственность;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Способность обучаться и осваивать новые технологии;",
          },
          {
            name: "list",
            type: "li",
            text: "Способность ориентироваться в чужом коде и работать в команде.",
          },
        ],
      },
    ],
    welcomed: [
      {
        title: "Приветствуeтся",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Опыт разработки кластерных систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Понимание архитектуры и принципов построения ОС;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание архитектуры и принципов работы файловых систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в проектах на уровне ядра ОС;",
          },
          {
            name: "list",
            type: "li",
            text: "Знание языка Python и опыта программирования с его использованием;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт разработки драйверов для SCSI и сетевых устройств;",
          },
          {
            name: "list",
            type: "li",
            text: "Осознание специфики низкоуровневой разработки;",
          },
          {
            name: "list",
            type: "li",
            text: "Стремление расширять свой кругозор и повышать профессиональный уровень;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в opensource проектах.",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Разработка модулей программного обеспечения на языке C для Unix-подобных систем;",
          },
          {
            name: "list",
            type: "li",
            text: "Участие в проектировании архитектуры ПО;",
          },
          {
            name: "list",
            type: "li",
            text: "Интеграция разработанных модулей в конечный продукт;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Поиск и исправление найденных ошибок, поддержка существующего кода;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в организации и проведении демонстраций продуктов.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе Москва, либо Воронеж/удаленно)",
          },
        ],
      },
    ],
  },

  {
    vacancy: "Technical Writer",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Опыт работы в данной области;",
          },
          {
            name: "list",
            type: "li",
            text: "Умение работать с большим количеством документов и информации;",
          },
          {
            name: "list",
            type: "li",
            text: "Английский язык (понимание технических текстов в области разработки ПО);",
          },
          {
            name: "list",
            type: "li",
            text: "Знание пакета MS Word и Visio на уровне необходимом для написания и оформления документов;",
          },
          {
            name: "list",
            type: "li",
            text: "Грамотная устная и письменная речь;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Умение излагать простым языком сложные вещи;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Развитые аналитический способности",
          },
        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Описание программных и аппаратных решений;",
          },
          {
            name: "list",
            type: "li",
            text: "Оформление конструкторской документации на программное обеспечение;",
          },
          {
            name: "list",
            type: "li",
            text: "Создание проектной документации (руководство пользователя, администратора, администратора API);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Создание обучающих технических презентаций;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Проверка на соответствие системы/функционала составляемому описанию.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе Москва, либо Воронеж/удаленно)",
          },
        ],
      },
    ],
  },

  {
    vacancy: "QA Engineer",
    requirements: [
      {
        title: "Требования",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Знание методологий тестирования ПО;",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с системами управления проектами (redmine, jira);",
          },
          {
            name: "list",
            type: "li",
            text: "Опыт работы с системой контроля версий (Git, SVN);",
          },
          {
            name: "list",
            type: "li",
            text: "Огромным плюсом будет знание и опыт работы с языками программирования (Java, C#, C++, JavaScript, Python) и опыт написания тестовых сценариев;",
          },
          {
            name: "list",
            type: "li",
            text: "Навык работы с selenium web driver;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Навык работы с ОС на уровне не ниже системного администратора, желательно умение писать сценарии на shell.",
          },

        ],
      },
    ],
    responsibilities: [
      {
        title: "Обязанности",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text: "Проведение модульного, интеграционного, системного, функционального, регрессионного, нагрузочного, стрессового, ручного и автоматизированного тестирования;",
          },
          {
            name: "list",
            type: "li",
            text: "Разработка стратегии тестирования;",
          },
          {
            name: "list",
            type: "li",
            text: "Разработка сценариев тестирования - тест-кейсов и тест-планов (работа с testlink);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Организация и поддержка в актуальном состоянии электронный архив документации;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Обнаружение, документирование и отслеживание ошибок в баг-трекинговой системе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Создание тестовой документации (ПМИ, отчеты об ошибках, changelog);",
          },
          {
            name: "list",
            type: "li",
            text:
              "Взаимодействие с аналитиками, разработчиками и руководителями проектов;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Обработка уведомлений об ошибках от пользователей.",
          },
        ],
      },
    ],
    conditions: [
      {
        title: "Условия",
        name: "title",
        type: "h4",
      },
      {
        name: "list",
        type: "ol",
        inside_blocks: [
          {
            name: "list",
            type: "li",
            text:
              "Стабильная, динамично развивающаяся компания, не обременённая бюрократией;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Участие в создании продуктов высокого класса, решение интересных задач; ",
          },
          {
            name: "list",
            type: "li",
            text:
              "Крутая команда профессионалов, дружелюбное отношение в коллективе;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Возможность профессионального и карьерного роста (внутреннее и внешнее обучение, конференции и профильные мероприятия за счет компании);",
          },
          {
            name: "list",
            type: "li",
            text: "Официальное оформление по ТК РФ;",
          },
          {
            name: "list",
            type: "li",
            text:
              "Достойная З/п по результатам собеседования, растущая с повышением квалификации;",
          },
          {
            name: "list",
            type: "li",
            text: "График работы - 5/2 (в офисе Москва, либо Воронеж/удаленно)",
          },
        ],
      },
    ],
  },
];

export default vacantionsData;
