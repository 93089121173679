const data = [
  {
    tableName: "B1000",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Блочный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "64",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "800",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "8",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "-",
      },
      {
        property: "Max инициаторов",
        value: "512",
      },
      {
        property: "Протоколы",
        value: "FC / iSCSI(iSER)",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "252",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "96",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },
      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF, 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "3.99",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд,Мгновенные снимки, Клоны,Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },

      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "F1000",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Блочный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "64",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "1600",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "-",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "8",
      },
      {
        property: "Max инициаторов",
        value: "-",
      },
      {
        property: "Протоколы",
        value: "NFS / SMB / FTP / AFP",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "480",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "216",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "8,12",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "UDS1000",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "64",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "256",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "1000",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "8",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "8",
      },
      {
        property: "Max инициаторов",
        value: "1024",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB / FTP / AFP",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "404",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "48",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF, 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "4,78",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "UDS2000",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "256",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "1024",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "3200",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "16",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "16",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "480",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "252",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF, 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "8,67",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "UDS8000",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "8",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "2048",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "6400",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "8",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "40",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "8",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "40",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "1616",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "480",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF, 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "23,53",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "UDS2500F",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "256",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "-",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "20",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "20",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "-",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "252",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "-",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "-",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "-",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value: "2U - 24 диска SFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "1,20",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "UDS4500F",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "4",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "1024",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "-",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "8",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "40",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "8",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "40",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "-",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "480",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "-",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "-",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "-",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value: "2U - 24 диска SFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "1,60",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "БФ.MS.2/20",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF4U-24-HDD/SSD",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "4",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "128",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "2048",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "6400",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "0",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "60000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "20",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "-",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "20",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "1616",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "480",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "300Gb / 450Gb / 600Gb",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "450Gb / 600Gb / 900Gb / 1.2Tb / 1.8Tb",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "2Tb / 4Tb / 5Tb / 6Tb / 8Tb / 10Tb",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value:
          "100Gb / 200Gb / 400Gb / 500Gb / 800Gb / 1Tb / 1.6Tb / 1,92Tb / 3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value:
          "2U -12 дисков LFF, 2U - 24 диска SFF, 4U -24 диска LFF, 4U - 76 дисков LFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B3",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "21,20",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 698.5 x 177.8",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "SPACE3500Nx",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF2U-24-NVMe",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "2",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "256",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "-",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "-",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "80000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "16",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "16",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "-",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "48",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "-",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "-",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "-",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value: "3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value: "2U - 24 диска SFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B4",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "0,74",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 815 x 88",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "SPACE6500Nx",
    tableProperties: [
      {
        property: "Тип СХД",
        value: "Унифицированный",
      },
      {
        property: "Платформа управления",
        value: "FF2U-24-NVMe",
      },
      {
        property: "Количество контроллеров в базовой комплектации",
        value: "2",
      },
      {
        property: "Максимальное количество контроллеров",
        value: "8",
      },
      {
        property: "Кэш уровень 1, ГБ",
        value: "512",
      },
      {
        property: "Max кэш уровень 1, ГБ",
        value: "1024",
      },
      {
        property: "Кэш уровень 2W, ГБ",
        value: "480",
      },
      {
        property: "Max кэш 2W, ГБ",
        value: "-",
      },
      {
        property: "Кэш уровень 2R, ГБ",
        value: "-",
      },
      {
        property: "Max кэш 2R, ГБ",
        value: "100000",
      },
      {
        property: "Порты SAS 12 Гб/c на контроллер, шт",
        value: "4",
      },
      {
        property: "Блоки питания, шт",
        value: "1+1 (1200 W)",
      },
      {
        property: "Mgmt порты на контроллер, 1 Гб/c",
        value: "2",
      },
      {
        property: "Порты 8/16/32 Гб/c FС (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 8/16/32 Гб/c FС (на модуль управления)",
        value: "16",
      },
      {
        property: "Портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "4",
      },
      {
        property: "Max портов 10/25/50 Гб/с Eth (на модуль управления)",
        value: "16",
      },
      {
        property: "Max инициаторов",
        value: "4096",
      },
      {
        property: "Протоколы",
        value: "iSCSI (iSER) / FC / NFS / SMB",
      },
      {
        property: "Дисков в контроллере",
        value: "24",
      },
      {
        property: "Кол-во поддерживаемых HDD",
        value: "-",
      },
      {
        property: "Кол-во поддерживаемых SSD/NVMe",
        value: "96",
      },
      {
        property: "Доступные SAS 15k диски (SFF)",
        value: "-",
      },
      {
        property: "Доступные SAS 10k диски (SFF)",
        value: "-",
      },

      {
        property: "Доступные SAS 7.2k диски (LFF)",
        value: "-",
      },
      {
        property: "Доступные SSD/NVMe диски (SFF)",
        value: "3,84Tb / 7,68Tb / 15,36Tb",
      },
      {
        property: "Дисковые полки",
        value: "2U - 24 диска SFF",
      },
      {
        property: "Уровни RAID",
        value: "1, 5, 6, 10, 50, 60, B5",
      },
      {
        property: "Максимально 'сырое' пространство, Pb",
        value: "1,47",
      },
      {
        property: "Базовое программное обеспечение",
        value:
          "Кластерное ПО, Мультирейд, Мгновенные снимки, Клоны, Компрессия, Ассинхронная репликация",
      },
      {
        property: "Дедупликация",
        value: "Опционально",
      },
      {
        property: "Синхронная репликация",
        value: "Опционально",
      },
      {
        property: "BMAPP",
        value: "Опционально",
      },
      {
        property: "Активация расширения системы мониторинга",
        value: "Опционально",
      },
      {
        property:
          "Активация подсистемы обеспечения консистентных снимков и клонов",
        value: "Опционально",
      },
      {
        property: "Миграция данных с/на СХД других производителей",
        value: "Опционально",
      },
      {
        property: "Габариты (ШхГхВ), мм (модуля управления)",
        value: "482.6 x 815 x 88",
      },
      {
        property: "Рабочая температура",
        value: "0° - 35°C",
      },
      {
        property: "Рабочая влажность",
        value: "5% - 95% без конденсации",
      },
      {
        property: "Гарантия (базовая)",
        value: "3 года, NBD",
      },
      {
        property: "Гарантия (расширенная)",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "Archive",
    tableProperties: [
      {
        property: "Плотность хранения данных",
        value: "до 192TB RAW на 1 RU",
      },
      {
        property: "Низкая стоимость",
        value: "за 1TB",
      },
      {
        property: "Возможность географического распределения системы",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "Flash",
    tableProperties: [
      {
        property: "lat",
        value: "< 1 mc",
      },
      {
        property: "lops",
        value: "> 1 млн",
      },
      {
        property: "Настраиваемая отказоусточивость",
        value: "Опционально",
      },
    ],
  },
  {
    tableName: "AIAP 2000",
    tableProperties: [
      {
        property: "Контроллер",
        value: "2",
      },
      {
        property: "СХД",
        value: "+",
      },
    ],
  },
  {
    tableName: "AIAP 6000",
    tableProperties: [
      {
        property: "Контроллер",
        value: "6",
      },
      {
        property: "СХД",
        value: "+",
      },
    ],
  },
];

export default data;
