const dataSoftware = [
  {
    tableProperties: [
      {
        certificateNumber: "2017612060",
        name: "Компьютерная программа для организации распределенной системы хранения и анализа информации «BAUMAN ALYTICS» Свидетельство на программу для ЭВМ № 2017612060 от 14.02.2017",
        description: "Программа применяется для создания распределенных хранилищ электронных документов, баз данных и файловых хранилищ",
      },
      {
        certificateNumber: "2018611810",
        name: "«BAUMAN ALYTICS ВЕРСИЯ 2» Свидетельство на программу для ЭВМ № 2018611810 от 07.02.2018",
        description: "Программа предназначена для организации доступа к данным по iSCSI протоколу. Программа применяется при создании распределенных хранилищ электронных документов, баз данных и файловых хранилищ. Функциональные возможности программы: масштабирование по объему; возможность повышения надежности; возможность сокращения задержек доступа; возможность обеспечения уровней информационной безопасности. Имеет встроенную функцию шифрования. Данные равномерно распределяются по модулям с использованием контроллеров. Контроллеры кешируют данные, а также ведут базу соответствий файлов набору блоков на дисках. Для отказоустойчивости применяется различные алгоритмы избыточного кодирования",
      },
      {
        certificateNumber: "2018616038",
        name: "Программа для улучшения комплексной функциональности ПО Тропосфера  Свидетельство на программу для ЭВМ № 2018616038 от 21.05.2018",
        description: "Программное обеспечение для улучшения комплексной функциональности ПО Тропосфера, а именно механизма интеграции функционала мгновенных снимков и резервного копирования со сторонним программным обеспечением. Функциональные возможности программы позволяют осуществлять консистентные снимки используемых приложений. Программное обеспечение для улучшения комплексной функциональности ПО Тропосфера, а именно механизма интеграции функционала мгновенных снимков и резервного копирования со сторонним программным обеспечением. Функциональные возможности программы позволяют осуществлять консистентные снимки используемых приложений. Программное обеспечение для улучшения комплексной функциональности ПО Тропосфера, а именно механизма интеграции функционала мгновенных снимков и резервного копирования со сторонним программным обеспечением. Функциональные возможности программы позволяют осуществлять консистентные снимки используемых приложений.",
      },
      {
        certificateNumber: "2018617768",
        name: "Модуль мониторинга СХД  Свидетельство на программу для ЭВМ № 2018617768 от 02.07.2018",
        description: "Программа предназначена для проведения мониторинга систем хранения данных, а также автоматизации процессов логирования.Программа применяется при эксплуатации систем хранения данных и автоматизации процессов логирования. Функциональные возможности программы: возможность визуализации изменения данных с течением времени. Возможность хранения логирования.",
      },
      {
        certificateNumber: "2018661136",
        name: "Программа для организации классической системы хранения и анализа информации версия 2 «BAUMAN ALYTICS STORAGE»  Свидетельствот на программу для ЭВМ № 2018661136 от 03.09.2018",
        description: "Программа предназначена для организации доступа к данным по «iSCSI» (internet Small Computer System Interface), «FC» (fiber channel), «NFS» (Network File System), «SMB» (Server Message Block)) протоколам. Программа применяется при создании классических хранилищ электронных документов, баз данных и файловых хранилищ. Программа предназначена для организации доступа к данным по «iSCSI» (internet Small Computer System Interface), «FC» (fiber channel), «NFS» (Network File System), «SMB» (Server Message Block)) протоколам. Программа применяется при создании классических хранилищ электронных документов, баз данных и файловых хранилищ. ",
      },
      {
        certificateNumber: "2020665265",
        name: "Сервер доступа по протоколу iSCSI  Свидетельство на программу для ЭВМ № 2020665265 от 24.11.2020",
        description: "",
      },
    ]
  }
]

export default dataSoftware;